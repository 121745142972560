import { LinkButton, DocsIcon } from '@backstage/core-components';
import React from 'react';
import { FeatureCard } from './index';

export const TechDocsFeatureCard = () => (
  <FeatureCard
    icon={<DocsIcon />}
    title="TechDocs"
    description="Read Markdown documentation published from repositories. Searchable using Backstage Search."
    readMoreLink="https://backstage.io/docs/features/techdocs/techdocs-overview"
  >
    <LinkButton variant="contained" color="primary" to="/docs">
      Go to TechDocs
    </LinkButton>
  </FeatureCard>
);
