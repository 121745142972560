import {
  createUnifiedTheme,
  createBaseThemeOptions,
  palettes,
  genPageTheme,
  shapes,
} from '@backstage/theme';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const cultureampDarkPalette = {
  dark: {
    type: 'dark',
    mode: 'dark',
    background: {
      default: '#1b1b1b',
      paper: '#212121',
    },
    bursts: {
      fontColor: '#ffffff',
      slackChannelText: '#ffffff',
      backgroundColor: {
        default: '#5f3361',
      },
      gradient: {
        linear: 'linear-gradient(45deg, #5f3361, #5f3361)',
      },
    },
    primary: {
      main: 'rgb(193, 135, 214)',
    },
    navigation: {
      background: '#222121',
      indicator: '#ebcaee',
      color: 'rgba(255, 255, 255, 0.7)',
      selectedColor: '#ebcaee',
      navItem: {
        hoverBackground: 'rgba(255, 255, 255, 0.1)',
      },
      submenu: {
        background: '#5f3361',
      },
    },
  } as Partial<PaletteOptions>,
};

export const cultureampDarkTheme = createUnifiedTheme({
  components: {
    BackstageHeader: {
      styleOverrides: {
        title: {
          fontSize: '24px',
        },
        header: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: 'none !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          fontSize: '20px',
        },
        h5: {
          fontSize: '18px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: '1px solid rgba(255,255,255,0.2) !important',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '7px',
        },
      },
    },
    BackstageSelectInputBase: {
      styleOverrides: {
        input: {
          border: '1px solid rgba(255,255,255,0.3)',
        },
      },
    },
    BackstageTableHeader: {
      styleOverrides: {
        header: {
          borderTop: '1px solid rgba(255,255,255,0.1)',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '34px',
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        root: {
          backgroundColor: '#1f1f1f',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: 'translate(12px, 6px) scale(0.65) !important',
        },
      },
    },
  },
  pageTheme: {
    home: genPageTheme({
      colors: ['#5f3361', '#914498'],
      shape: shapes.round,
    }),
    documentation: genPageTheme({
      colors: ['#004970', '#008bd6'],
      shape: shapes.round,
    }),
    tool: genPageTheme({
      colors: ['#b74302', '#ff9461'],
      shape: shapes.round,
    }),
    service: genPageTheme({
      colors: ['#2c7d67', '#5dcaad'],
      shape: shapes.round,
    }),
    website: genPageTheme({
      colors: ['#2c7d67', '#5dcaad'],
      shape: shapes.round,
    }),
    library: genPageTheme({
      colors: ['#c68600', '#ffca4d'],
      shape: shapes.round,
    }),
    api: genPageTheme({
      colors: ['#b74302', '#ff9461'],
      shape: shapes.round,
    }),
    other: genPageTheme({
      colors: ['#b74302', '#ff9461'],
      shape: shapes.round,
    }),
    app: genPageTheme({
      colors: ['#b74302', '#ff9461'],
      shape: shapes.round,
    }),
  },
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      ...cultureampDarkPalette.dark,
    },
  }),
});
