/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable no-restricted-imports */

import {
  Alert,
  AlertCost,
  CostInsightsApi,
  ProductInsightsOptions,
  ProjectGrowthAlert,
  ProjectGrowthData,
} from '@backstage-community/plugin-cost-insights';
import {
  Cost,
  DateAggregation,
  Entity,
  Group,
  MetricData,
  Project,
} from '@backstage-community/plugin-cost-insights-common';
import { GetEntityFacetsResponse } from '@backstage/catalog-client';
import { ConfigApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { aggregationFor, changeOf, trendlineOf } from './testUtils';

export class ProxyCostInsightsClient implements CostInsightsApi {
  readonly baseUrl: string;
  readonly products: Record<string, string> = {};
  readonly catalogClient: CatalogApi;
  lastCompleteBillingDate: string = '';
  groupDailyCostRequests: Record<string, Cost> = {};
  constructor(config: ConfigApi, catalogClient: CatalogApi) {
    const externalBaseUrl = config.getString('backend.baseUrl');
    this.baseUrl = `${externalBaseUrl}/api/proxy/aws-cost-insights`;
    this.products = this.getProducts(config);
    this.catalogClient = catalogClient;
  }

  private getProducts(config: ConfigApi): Record<string, string> {
    const products = config.getConfig('costInsights.products');
    return products.keys().reduce((productMap, key) => {
      productMap[key] = products.getString(`${key}.name`);
      return productMap;
    }, {});
  }

  private async defaultIntervals(): Promise<string> {
    const toDate = await this.cachedLastCompleteBillingDate();
    return `R2/P90D/${toDate}`;
  }

  private async quarterlyIntervals(): Promise<string> {
    const toDate = await this.cachedLastCompleteBillingDate();
    return `R2/P3M/${toDate}`;
  }

  private async cachedLastCompleteBillingDate(): Promise<string> {
    return (
      this.lastCompleteBillingDate ?? (await this.getLastCompleteBillingDate())
    );
  }

  private request(_: any, res: any): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, 0, res));
  }

  async getLastCompleteBillingDate(): Promise<string> {
    const fetched = await fetch(`${this.baseUrl}/getLastCompleteBillingDate`);
    this.lastCompleteBillingDate = await fetched.json();
    return this.lastCompleteBillingDate;
  }

  // User friendly casing for camp names
  titleCase(string: string): string {
    const casedString = string
      .split('-')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');

    return casedString;
  }

  // Extract the camp tags out of the given entity tags
  // and format for the group filter
  formCampGroup(catalogTags: GetEntityFacetsResponse): Group[] {
    const campGroups = catalogTags.facets['metadata.tags']
      .filter(tag => tag.value.startsWith('camp-'))
      .map(tag => tag.value.replace('camp-', ''))
      .map(tagValue => ({
        id: tagValue,
        name: this.titleCase(tagValue),
      }));

    return campGroups;
  }

  async getUserGroups(userId: string): Promise<Group[]> {
    // Fetch Component entity tags from the catalog
    const catalogTags = await this.catalogClient.getEntityFacets({
      facets: ['metadata.tags'],
      filter: { kind: ['Component'] },
    });

    const campGroups = this.formCampGroup(catalogTags);

    const groups: Group[] = await this.request({ userId }, campGroups);

    return groups;
  }

  async getGroupProjects(group: string): Promise<Project[]> {
    const url = new URL(`${this.baseUrl}/getGroupDailyCost`);
    url.search = new URLSearchParams({
      group: group,
      intervals: await this.defaultIntervals(),
    }).toString();

    const fetched = await fetch(url.toString());
    const cost: Cost = await fetched.json();
    return this.extractProjects(cost);
  }

  async getDailyMetricData(
    metric: string,
    intervals: string,
  ): Promise<MetricData> {
    const aggregation = aggregationFor(intervals, 100_000).map(entry => ({
      ...entry,
      amount: Math.round(entry.amount),
    }));

    const cost: MetricData = await this.request(
      { metric, intervals },
      {
        format: 'number',
        aggregation: aggregation,
        change: changeOf(aggregation),
        trendline: trendlineOf(aggregation),
      },
    );

    return cost;
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
    const url = new URL(`${this.baseUrl}/getGroupDailyCost`);
    url.search = new URLSearchParams({
      group: group,
      intervals: intervals,
    }).toString();

    const fetched = await fetch(url.toString());
    return await fetched.json();
  }

  extractProjects(cost: Cost): Project[] {
    if (cost.groupedCosts === undefined) {
      return [];
    }

    return cost.groupedCosts.asset.map(c => {
      return {
        id: c.id,
      };
    });
  }

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    const url = new URL(`${this.baseUrl}/getProjectDailyCost`);
    url.search = new URLSearchParams({
      project: project,
      intervals: intervals,
    }).toString();
    const fetched = await fetch(url.toString());
    return await fetched.json();
  }

  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    const url = new URL(`${this.baseUrl}/getProductInsights`);
    const params = new URLSearchParams({
      product: this.products[options.product],
      group: options.group,
      intervals: options.intervals,
    });
    if (options.project) {
      params.set('project', options.project);
    }
    url.search = params.toString();
    const fetched = await fetch(url.toString());
    const insights: Entity = await fetched.json();
    for (const key of Object.keys(insights.entities)) {
      const entities = insights.entities[key];
      for (const entity of entities) {
        if (entity.id === '' || entity.id === null) {
          entity.id = 'Unlabelled';
        }
        entity.entities = {};
        entity.change = {
          amount: entity.aggregation[1] - entity.aggregation[0],
          ratio:
            (entity.aggregation[1] - entity.aggregation[0]) /
            (entity.aggregation[0] === 0 ? 1 : entity.aggregation[0]),
        };
      }
    }
    return insights;
  }

  async getCatalogEntityDailyCost(
    catalogEntityRef: string,
    intervals: string,
  ): Promise<Cost> {
    const entityName = catalogEntityRef.split('/').pop();

    const entityDailyCost: Cost = await this.getProjectDailyCost(
      entityName!,
      intervals,
    );

    return entityDailyCost;
  }

  aggregate(aggregation: DateAggregation[]): [number, number] {
    const amounts = aggregation.map(a => a.amount);
    const startAmount = amounts
      .slice(0, amounts.length / 2 - 1)
      .reduceRight((t, c) => t + c);
    const endAmount = amounts
      .slice(amounts.length / 2, amounts.length - 1)
      .reduceRight((t, c) => t + c);

    return [startAmount, endAmount];
  }

  mapToArray(records: Record<string, Cost[]>): AlertCost[] {
    const alertCosts: AlertCost[] = [];
    const firstId = Object.keys(records)[0];
    const costs = records[firstId];

    for (let index = 0; index < costs.length; index++) {
      const cost = costs[index];
      alertCosts.push({
        id: cost.id,
        aggregation: this.aggregate(cost.aggregation),
      });
    }
    return alertCosts;
  }

  getQuarter(dateStr: string, quartersAgo: number = 1): string {
    const date = new Date(dateStr);
    date.setMonth(date.getMonth() - quartersAgo * 3);
    return this.formatQuarter(date);
  }

  formatQuarter(date: Date): string {
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    const formattedQuarter = `${date.getFullYear()}-Q${quarter}`;
    // eslint-disable-next-line no-console
    console.log(`---- The Formatted Quarter: ${formattedQuarter}`);
    return formattedQuarter;
  }

  async getAlerts(group: string): Promise<Alert[]> {
    const url = new URL(`${this.baseUrl}/getGroupDailyCost`);
    url.search = new URLSearchParams({
      group: group,
      intervals: await this.quarterlyIntervals(),
    }).toString();

    const fetched = await fetch(url.toString());
    const cost: Cost = await fetched.json();
    const lastBillingDate = await this.cachedLastCompleteBillingDate();
    if (
      cost.groupedCosts &&
      cost.change?.ratio !== undefined &&
      cost.change.ratio > 0.5
    ) {
      const groupGrowth: ProjectGrowthData = {
        project: group,
        periodStart: this.getQuarter(lastBillingDate, 2),
        periodEnd: this.getQuarter(lastBillingDate, 1),
        aggregation: this.aggregate(cost.aggregation),
        change: cost.change!,
        products: this.mapToArray(cost.groupedCosts),
      };

      const alerts: Alert[] = await this.request({ group }, [
        new ProjectGrowthAlert(groupGrowth),
      ]);
      return alerts;
    }
    return [];
  }
}
