import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  useApi,
  identityApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';

export const DatadogRumInit = () => {
  const configApi = useApi(configApiRef);

  const environment = configApi.getString('app.environment');
  const revision = configApi.getOptionalString('app.revision') || 'unknown';
  const datadogApplicationId = configApi.getOptionalString(
    'app.datadogRum.applicationId',
  );
  const datadogClientToken = configApi.getOptionalString(
    'app.datadogRum.clientToken',
  );

  if (datadogApplicationId && datadogClientToken) {
    datadogRum.init({
      applicationId: 'fd06ba2a-81b9-491b-a99a-caff6b87a292',
      clientToken: 'pub92c2c1159088ffd636c6dd79aa47594e',
      site: 'datadoghq.com',
      service: 'backstage',
      env: environment,
      version: revision,
      trackUserInteractions: true,
      traceSampleRate: 30,
      traceContextInjection: 'sampled',
      allowedTracingUrls: [configApi.getString('app.baseUrl')],
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Datadog RUM is missing `applicationId` and/or `clientToken`. User session not being logged to Datadog!',
    );
  }

  return null;
};

export const DatadogRumUserIdentity = () => {
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    const setUser = async () => {
      const profile = await identityApi.getProfileInfo();
      datadogRum.setUser({
        id: profile.email ?? 'Unknown',
        name: profile.displayName ?? 'Unknown',
      });
    };

    setUser();

    return function cleanup() {
      datadogRum.setUser({});
    };
  });

  return null;
};
