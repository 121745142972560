import React from 'react';
import { LinkButton } from '@backstage/core-components';
import { FeatureCard } from './index';
import PetsIcon from '@material-ui/icons/Pets';

export const FarmsFeatureCard = () => (
  <FeatureCard
    icon={<PetsIcon />}
    title="Farms"
    description="Explore Culture Amp development farms and their owners."
  >
    <LinkButton variant="contained" color="primary" to="/farms">
      Go to Farms
    </LinkButton>
  </FeatureCard>
);
