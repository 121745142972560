import React from 'react';
import { LinkButton, InfoCard } from '@backstage/core-components';
import { Box } from '@material-ui/core';
import GithubIcon from '@material-ui/icons/GitHub';

const NoCurrentUserTitle = () => (
  <Box display="flex" alignItems="center">
    <GithubIcon />
    <Box ml={1}>Missing Github username</Box>
  </Box>
);

const bodyText = `
Your Github identity cannot be determined so ownership information is
unavailable. Please sign in to Github using the sidebar. Alternatively,
Backstage can determine your Github username from your Okta
login: ensure your Github username is set in your BambooHR profile and
check with Tech that it has been added to your Okta profile.
`;

export const NoCurrentUserCard = () => (
  <InfoCard title={<NoCurrentUserTitle />} subheader={bodyText}>
    <LinkButton
      variant="contained"
      color="secondary"
      to="/docs/default/component/backstage/github-identity/"
    >
      Read more
    </LinkButton>
  </InfoCard>
);
