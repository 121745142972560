import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  ciCdRoute,
  docsRoute,
  overviewRoute,
  pullRequestsRoute,
} from '../routes';

export const libraryComponentPage = (
  <EntityLayout>
    {overviewRoute}
    {ciCdRoute}
    {docsRoute}
    {pullRequestsRoute}
  </EntityLayout>
);
