import React from 'react';
import { LinkButton } from '@backstage/core-components';
import { FeatureCard } from './index';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

export const CostInsightsFeatureCard = () => (
  <FeatureCard
    icon={<MoneyIcon />}
    title="Cost Insights"
    description="Explore AWS spend by camp and service."
    readMoreLink="https://github.com/backstage/backstage/tree/master/plugins/cost-insights"
  >
    <LinkButton variant="contained" color="primary" to="/cost-insights">
      Go to Cost Insights
    </LinkButton>
  </FeatureCard>
);
