import {
  Content,
  ContentHeader,
  PageWithHeader,
} from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid } from '@material-ui/core';
import React from 'react';
import { CurrentUserInfo } from './CurrentUserInfo';
import {
  CatalogFeatureCard,
  CostInsightsFeatureCard,
  FarmsFeatureCard,
  ScaffolderFeatureCard,
  TechDocsFeatureCard,
} from './features';

export const HomePage = () => (
  <PageWithHeader title="Welcome to Backstage for Culture Amp" themeId="home">
    <Content>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchContextProvider>
            <HomePageSearchBar />
          </SearchContextProvider>
        </Grid>
        <Grid item xs={12}>
          <CurrentUserInfo />
        </Grid>
        <Grid item xs={12}>
          <ContentHeader title="Explore Backstage" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CatalogFeatureCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TechDocsFeatureCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ScaffolderFeatureCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CostInsightsFeatureCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FarmsFeatureCard />
        </Grid>
      </Grid>
    </Content>
  </PageWithHeader>
);
