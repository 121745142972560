import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  CampSelector,
  CampSelectorSchema,
} from './CampSelector/CampSelectorExtension';

export const CampSelectorFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CampSelector',
    component: CampSelector,
    schema: CampSelectorSchema,
  }),
);
