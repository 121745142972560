import {
  githubAuthApiRef,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import { useState, useCallback, useEffect } from 'react';

/**
 * Github Auth API returns a profile that doesn't conform to the expected
 * types
 */
type GithubProfile = Omit<ProfileInfo, 'email'> & {
  username: string;
};

export const useGithubUsername = () => {
  const githubApi = useApi(githubAuthApiRef);
  const [githubUsername, setGithubUsername] = useState(
    undefined as string | undefined,
  );

  const updateUsername = useCallback(async () => {
    const profile = (await githubApi.getProfile({
      optional: true,
    })) as GithubProfile | undefined;
    setGithubUsername(profile?.username);
  }, [githubApi, setGithubUsername]);

  // initially set the username based on current state of github session
  updateUsername();

  // subscribe to changes to Github auth provider session status to rerender on login and logout
  useEffect(() => {
    const subscription = githubApi
      .sessionState$()
      .subscribe(() => updateUsername());

    return () => {
      subscription.unsubscribe();
    };
  }, [githubApi, updateUsername]);

  return githubUsername;
};
