import React from 'react';
import {
  EntityAboutCard,
  EntityHasSubcomponentsCard,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import {
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReadmeCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityGithubPullRequestsOverviewCard,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { ContainerVulnerabilitiesSummaryCard } from '@internal/plugin-container-vulns';

export const overviewContent = (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item xs={12} md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item xs={12} md={6}>
      <EntityCatalogGraphCard variant="gridItem" height={350} />
    </Grid>
    <Grid item xs={12} md={6}>
      <EntityLinksCard />
    </Grid>
    <Grid item xs={12} md={6}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item xs={12} md={6}>
          <EntityGithubInsightsLanguagesCard />
          <EntityGithubInsightsReadmeCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <Grid item xs={12} md={6}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid item xs={12} md={6}>
      <ContainerVulnerabilitiesSummaryCard />
    </Grid>
  </Grid>
);
