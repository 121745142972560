import React from 'react';
import { InfoCard, Link } from '@backstage/core-components';
import { Box, Typography } from '@material-ui/core';

type FeatureCardTitleProps = { icon: React.ReactElement; title: string };

const FeatureCardTitle = (props: FeatureCardTitleProps) => (
  <Box display="flex" alignItems="center">
    {props.icon}
    <Box ml={1}>{props.title}</Box>
  </Box>
);

type FeatureCardDescriptionProps = {
  description: string;
  link?: string;
};
const FeatureCardDescription = (props: FeatureCardDescriptionProps) => (
  <Typography>
    {props.description} {props.link && <Link to={props.link}>Read more</Link>}
  </Typography>
);

type FeatureCardProps = {
  icon: React.ReactElement;
  title: string;
  description: string;
  readMoreLink?: string;
};

export const FeatureCard = (
  props: React.PropsWithChildren<FeatureCardProps>,
) => (
  <InfoCard
    title={<FeatureCardTitle icon={props.icon} title={props.title} />}
    subheader={
      <FeatureCardDescription
        description={props.description}
        link={props.readMoreLink}
      />
    }
  >
    {props.children}
  </InfoCard>
);
