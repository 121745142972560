import React from 'react';
import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import { defaultEntityPage } from './defaultEntityPage';
import { libraryComponentPage } from './libraryComponentPage';
import { serviceComponentPage } from './serviceComponentPage';
import { websiteComponentPage } from './websiteComponentPage';

export const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceComponentPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteComponentPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      {libraryComponentPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
