import { Progress } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityLoadingStatus,
  EntityProvider,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { useAsync } from 'react-use';
import Alert from '@material-ui/lab/Alert';
import { useGithubUsername } from '../../useGithubUsername';

export const useCurrentUserEntity = (): EntityLoadingStatus => {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const githubUsername = useGithubUsername();

  const {
    value: entity,
    error,
    loading,
  } = useAsync(async () => {
    // try: determine catalog entity from Backstage identity
    const identity = await identityApi.getBackstageIdentity();
    const identityEntity = await catalogApi.getEntityByRef(
      identity.userEntityRef,
    );
    if (identityEntity) {
      return identityEntity;
    }

    // try: determine catalog entity directly from Github username, if there is one
    if (githubUsername) {
      const githubUserEntity = await catalogApi.getEntityByRef(
        `user:default/${githubUsername}`,
      );
      if (githubUserEntity) {
        return githubUserEntity;
      }
    }

    // no entity for user is identifiable
    return undefined;
  }, [githubUsername]);

  return { entity, loading, error };
};

export const CurrentUserEntityProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { entity, loading, error } = useCurrentUserEntity();

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return <EntityProvider entity={entity}>{props.children}</EntityProvider>;
};
