/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import {
  ExplorePage,
  explorePlugin,
} from '@backstage-community/plugin-explore';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { searchPage } from './components/search/SearchPage';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import React from 'react';
import { Route } from 'react-router-dom';
import { apis } from './apis';
import { entityPage } from './components/catalog';
import Root from './components/Root';
import { CostInsightsPage } from '@backstage-community/plugin-cost-insights';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { createApp } from '@backstage/app-defaults';
import { FarmsPage } from '@internal/plugin-farms';
import {
  RELATION_OWNER_OF,
  RELATION_OWNED_BY,
  RELATION_CONSUMES_API,
  RELATION_API_CONSUMED_BY,
  RELATION_PROVIDES_API,
  RELATION_API_PROVIDED_BY,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
} from '@backstage/catalog-model';
import { DatadogRumInit, DatadogRumUserIdentity } from './datadog';
import { SignInPage } from './components/SignInPage';
import { HomePage } from './components/home';
import { CampSelectorFieldExtension } from './scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { cultureampLightTheme } from './theme/cultureampLightTheme';
import { cultureampDarkTheme } from './theme/cultureampDarkTheme';
import { themes, UnifiedThemeProvider } from '@backstage/theme';

const app = createApp({
  apis,
  components: {
    SignInPage: props => <SignInPage {...props} />,
  },
  themes: [
    {
      id: 'cultureampLight',
      title: 'Culture Amp Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={cultureampLightTheme}
          children={children}
        />
      ),
    },
    {
      id: 'cultureampDark',
      title: 'Culture Amp Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={cultureampDarkTheme} children={children} />
      ),
    },
    {
      id: 'light',
      title: 'Backstage Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.light} children={children} />
      ),
    },
    {
      id: 'dark',
      title: 'Backstage Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <CampSelectorFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route
      path="/entire-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'resource'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
            rootEntityRefs: ['domain:engagement'],
          }}
        />
      }
    />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route path="/farms" element={<FarmsPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <DatadogRumInit />
    <OAuthRequestDialog />
    <AppRouter>
      <DatadogRumUserIdentity />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
