import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import React from 'react';
import { apiPage } from './apiPage';
import { componentPage } from './componentPage';
import { defaultEntityPage } from './defaultEntityPage';
import { domainPage } from './domainPage';
import { groupPage } from './groupPage';
import { systemPage } from './systemPage';
import { userPage } from './userPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
