import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  overviewRoute,
  ciCdRoute,
  docsRoute,
  pullRequestsRoute,
  sentryRoute,
  containerVulnerabilitiesRoute,
  pagerDutyRoute,
} from '../routes';

export const websiteComponentPage = (
  <EntityLayout>
    {overviewRoute}
    {ciCdRoute}
    {containerVulnerabilitiesRoute}
    {pullRequestsRoute}
    {docsRoute}
    {sentryRoute}
    {pagerDutyRoute}
  </EntityLayout>
);
