import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

const CampSelectorFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    focused: z.boolean().optional().describe('Whether to focus this field'),
  }),
);

export const CampSelectorSchema = CampSelectorFieldSchema.schema;

export type CampSelectorProps = typeof CampSelectorFieldSchema.type;

interface Camp {
  label: string;
  value: string;
}

const camps: Camp[] = [
  {
    label: 'Amplify',
    value: 'AMPLIFY',
  },
  {
    label: 'Career',
    value: 'CAREER',
  },
  {
    label: 'Core Experiences',
    value: 'CORE_EXPERIENCES',
  },
  {
    label: 'Cultivate',
    value: 'CULTIVATE',
  },
  {
    label: 'Culture Lab',
    value: 'CULTURE_LAB',
  },
  {
    label: 'Data Intelligence',
    value: 'DATA_INTELLIGENCE',
  },
  {
    label: 'Develop',
    value: 'DEVELOP',
  },
  {
    label: 'Developer Experience',
    value: 'DEVELOPER_EXPERIENCE',
  },
  {
    label: 'Engagement',
    value: 'ENGAGEMENT',
  },
  {
    label: 'Marketing',
    value: 'MARKETING',
  },
  {
    label: 'Perform',
    value: 'PERFORM',
  },
  {
    label: 'Platform',
    value: 'PLATFORM',
  },
  {
    label: 'Scale and Connect',
    value: 'SCALE_AND_CONNECT',
  },
  {
    label: 'Security',
    value: 'SECURITY',
  },
  {
    label: 'SRE',
    value: 'SRE',
  },
  {
    label: 'Talent',
    value: 'TALENT',
  },
  {
    label: 'Technology',
    value: 'TECHNOLOGY',
  },
  {
    label: 'Unify Core',
    value: 'UNIFY_CORE',
  },
  {
    label: 'Unify Habits',
    value: 'UNIFY_HABITS',
  },
  {
    label: 'Unify Products',
    value: 'UNIFY_PRODUCTS',
  },
];

export const CampSelector = (props: CampSelectorProps) => {
  const {
    onChange,
    schema: {
      title = 'Camp',
      description = 'The camp that this repo belongs to',
    },
    required,
    rawErrors,
    formData,
    idSchema,
  } = props;

  const selectedCamp = camps?.find(e => e.label === formData);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
      focused={props.focused}
    >
      <Autocomplete
        id={idSchema?.$id}
        value={selectedCamp}
        onChange={(_, value) => onChange(value?.value)}
        options={camps}
        getOptionLabel={option => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        autoHighlight
        autoSelect
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
