import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const containerVulnerabilitiesPlugin = createPlugin({
  id: 'container-vulns',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'enable-container-vulns' }],
});

export const ContainerVulnerabilitiesPage =
  containerVulnerabilitiesPlugin.provide(
    createRoutableExtension({
      name: 'ContainerVulnerabilitiesPage',
      component: () =>
        import('./components/ContainerVulnerabilitiesPage').then(
          m => m.ContainerVulnerabilitiesPage,
        ),
      mountPoint: rootRouteRef,
    }),
  );
