import React from 'react';
import {
  InfoCard,
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
  StatusWarning,
  Table,
  TableColumn
} from '@backstage/core-components';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { SeverityTally } from '../types';

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const severities = [
  { label: "CRITICAL", statusComponent: StatusError },
  { label: "HIGH", statusComponent: StatusWarning },
  { label: "MEDIUM", statusComponent: StatusPending },
  { label: "LOW", statusComponent: StatusOK },
  { label: "INFORMATIONAL", statusComponent: StatusRunning },
  { label: "UNDEFINED", statusComponent: StatusAborted }
]

function getTableData(severityTally : SeverityTally) {
  return severities.map(({ label, statusComponent: StatusComponent }) => ({
    "Severity": <StatusComponent>{label}</StatusComponent>, "Frequency": severityTally[label] ?? 0
  }))
}

type ContainerVulnerabilitiesSummaryCardViewProps = {
  title: string
  loading?: boolean
  severityTally?: SeverityTally
}

export const ContainerVulnerabilitiesSummaryCardView = ({ title, loading, severityTally }: ContainerVulnerabilitiesSummaryCardViewProps) => {
  const classes = useStyles();

  const columns: TableColumn[] = [
    { title: 'Severity', field: 'Severity' },
    { title: 'Frequency', field: 'Frequency' },
  ];

  const data = severityTally ? getTableData(severityTally) : []
  const emptyContent = loading
    ? (<div className={classes.empty}><CircularProgress /></div>)
    : (<div className={classes.empty}>No data available.</div>)

  return (
    <InfoCard title={title} noPadding>
      <Table
        options={{ search: false, paging: false, padding: 'dense', toolbar: false }}
        columns={columns}
        data={data}
        emptyContent={emptyContent}
      />
    </InfoCard>
  );
};
