import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { docsRoute, overviewRoute, pullRequestsRoute } from '../routes';

export const defaultEntityPage = (
  <EntityLayout>
    {overviewRoute}
    {docsRoute}
    {pullRequestsRoute}
  </EntityLayout>
);
