import React from 'react';
import { useAsync } from 'react-use';
import { useConfigApi } from '../../config';
import { ContainerVulnerabilitiesSummaryCardView } from './ContainerVulnerabilitiesSummaryCardView'
import { useEntity } from '@backstage/plugin-catalog-react';
import { VulnerabilityReport } from '../types';

export const ContainerVulnerabilitiesSummaryCard = () => {
  const {entity} = useEntity();
  const registryId = "226140413739"; // cultureamp-continuous-integration
  const repositoryName = entity?.metadata.annotations?.["container-vulns/ecr-repo-name"]
    || (entity?.metadata.name && `master/${entity?.metadata.name}`);
  const imageTag = entity?.metadata.annotations?.["container-vulns/image-tag"];

  const params : {registryId: string, repositoryName: string, imageTag?: string} = {registryId, repositoryName}
  if(imageTag) {
    params.imageTag = imageTag
  }

  const backendBaseUrl = useConfigApi().getString('backend.baseUrl');
  const containerVulnsUrl = new URL(`${backendBaseUrl}/api/proxy/container-vulns/api`);
  containerVulnsUrl.search = new URLSearchParams(params).toString();

  const { value, loading } = useAsync(async (): Promise<VulnerabilityReport> => {
    const response = await fetch(containerVulnsUrl.toString());
    return await response.json();
  }, undefined);

  return (
    <ContainerVulnerabilitiesSummaryCardView
      title="Container Vulnerabilities"
      loading={loading}
      severityTally={value?.ImageScanFindings?.FindingSeverityCounts}
    />
  )
};
