import React from 'react';
import { SidebarItem } from '@backstage/core-components';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import GithubIcon from '@material-ui/icons/GitHub';
import { useGithubUsername } from '../../useGithubUsername';

export const SidebarGithubId = () => {
  const githubUsername = useGithubUsername();
  const githubApi = useApi(githubAuthApiRef);

  if (githubUsername) {
    return (
      <SidebarItem
        icon={GithubIcon}
        to={`/catalog/default/user/${githubUsername}`}
        text={githubUsername}
      />
    );
  }
  const onClick = (e: React.MouseEvent) => {
    githubApi.signIn();
    e.preventDefault();
  };
  return (
    <SidebarItem
      icon={GithubIcon}
      onClick={onClick}
      text="Sign in to GitHub"
    />
  );
};
