import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { ciCdContent, overviewContent } from './content';
import { ContainerVulnerabilitiesPage } from '@internal/plugin-container-vulns';
import { EntitySentryContent } from '@backstage-community/plugin-sentry';
import { EntityCostInsightsContent } from '@backstage-community/plugin-cost-insights';
import { Grid } from '@material-ui/core';
import {
  EntityProvidedApisCard,
  EntityConsumedApisCard,
} from '@backstage/plugin-api-docs';
import { isSentryAvailable } from './isSentryAvailable';
import {
  EntityTechdocsContent,
  isTechDocsAvailable,
} from '@backstage/plugin-techdocs';
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { EntityKafkaContent, isKafkaAvailable } from '@backstage-community/plugin-kafka';
import {
  EntityPagerDutyCard,
  isPagerDutyAvailable,
} from '@pagerduty/backstage-plugin';

export const apisRoute = (
  <EntityLayout.Route path="/api" title="APIs">
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={6}>
        <EntityProvidedApisCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <EntityConsumedApisCard />
      </Grid>
    </Grid>
  </EntityLayout.Route>
);

export const ciCdRoute = (
  <EntityLayout.Route path="/ci-cd" title="CI/CD">
    {ciCdContent}
  </EntityLayout.Route>
);

export const containerVulnerabilitiesRoute = (
  <EntityLayout.Route path="/vulnerabilities" title="Vulnerabilities">
    <ContainerVulnerabilitiesPage />
  </EntityLayout.Route>
);
export const docsRoute = (
  <EntityLayout.Route path="/docs" title="Docs" if={isTechDocsAvailable}>
    <EntityTechdocsContent />
  </EntityLayout.Route>
);

export const kafkaRoute = (
  <EntityLayout.Route path="/kafka" title="Kafka" if={isKafkaAvailable}>
    <EntityKafkaContent />
  </EntityLayout.Route>
);

export const overviewRoute = (
  <EntityLayout.Route path="/" title="Overview">
    {overviewContent}
  </EntityLayout.Route>
);

export const pagerDutyRoute = (
  <EntityLayout.Route path="/oncall" title="On Call" if={isPagerDutyAvailable}>
    <EntityPagerDutyCard />
  </EntityLayout.Route>
);

export const pullRequestsRoute = (
  <EntityLayout.Route
    path="/pull-requests"
    title="Pull Requests"
    if={isGithubPullRequestsAvailable}
  >
    <EntityGithubPullRequestsContent />
  </EntityLayout.Route>
);

export const sentryRoute = (
  <EntityLayout.Route path="/errors" title="Errors" if={isSentryAvailable}>
    <EntitySentryContent />
  </EntityLayout.Route>
);

export const costInsightsRoute = (
  <EntityLayout.Route path="/cost-insights" title="Cost Insights">
    <EntityCostInsightsContent />
  </EntityLayout.Route>
)
