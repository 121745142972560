import React from 'react';
import { SignInPage as BackstageSignInPage } from '@backstage/core-components';
import {
  DiscoveryApi,
  discoveryApiRef,
  IdentityApi,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import { oktaProvider } from '../identityProviders';

/**
 * Culture Amp Backstage sign-in page:
 * - configured with Okta auth provider
 * - fetches cookie from backend on sign-in and sets refresh
 *
 * @param props
 * @returns Rendered sign-in page component
 */
export const SignInPage = (props: React.PropsWithChildren<SignInPageProps>) => {
  const discoveryApi = useApi(discoveryApiRef);
  const onSignInSuccess = async (identityApi: IdentityApi) => {
    await fetchBackstageTokenCookie(discoveryApi, identityApi);
    props.onSignInSuccess(identityApi);
  };

  return (
    <BackstageSignInPage
      {...props}
      providers={[oktaProvider]}
      onSignInSuccess={onSignInSuccess}
    />
  );
};

/**
 * Set `backstage_token` cookie using https://backstage.url/api/cookie and
 * set up timeout to perform periodic fetch to keep the cookie refreshed
 *
 * @param discoveryApi Discovery API for determining the backend URL
 * @param identityApi  Discovery API for getting token to auth with backend
 * @returns void
 */
async function fetchBackstageTokenCookie(
  discoveryApi: DiscoveryApi,
  identityApi: IdentityApi,
) {
  const { token } = await identityApi.getCredentials();
  if (!token) {
    return;
  }

  const url = await discoveryApi.getBaseUrl('cookie');

  const retrieveCookie = async () => {
    await fetch(url, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  await retrieveCookie();
  setTimeout(retrieveCookie, 300000); // 5 minutes
}
