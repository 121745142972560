import { ContentHeader } from '@backstage/core-components';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  EntityUserProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import React from 'react';
import { CurrentUserEntityProvider } from './CurrentUserEntityProvider';
import { NoCurrentUserCard } from './NoCurrentUserCard';

/**
 * EntitySwitch.Case doesn't appear to render the default case if there is
 * no Entity in context.
 *
 * This component will only render its children if useEntity() throws an
 * error which indicates that there is no entity set in context by an entity
 * provider
 *
 * @param props containing children to render
 * @returns children if there is no provided Entity in context
 */
 const IfNoEntity = (props: React.PropsWithChildren<{}>) => {
  try {
    useEntity();
    return null;
  } catch (e) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export const CurrentUserInfo = () => (
  <React.Fragment>
    <ContentHeader title="About You" />
    <CurrentUserEntityProvider>
      <Grid container spacing={3}>
        <EntitySwitch>
          <EntitySwitch.Case if={isKind('user')}>
            <Grid item xs={12} md={6}>
              <EntityUserProfileCard variant="gridItem" />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityOwnershipCard variant="gridItem" />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
        <IfNoEntity>
          <Grid item xs={12}>
            <NoCurrentUserCard />
          </Grid>
        </IfNoEntity>
      </Grid>
    </CurrentUserEntityProvider>
  </React.Fragment>
);
