import React from 'react';
import { LinkButton, CatalogIcon } from '@backstage/core-components';
import { FeatureCard } from './index';

export const CatalogFeatureCard = () => (
  <FeatureCard
    icon={<CatalogIcon />}
    title="Software Catalog"
    description="Explore catalog of services and libraries pulled in from details in repositories. Searchable using Backstage Search."
    readMoreLink="https://backstage.io/docs/features/software-catalog/software-catalog-overview"
  >
    <LinkButton variant="contained" color="primary" to="/catalog">
      Go to Catalog
    </LinkButton>
  </FeatureCard>
);
