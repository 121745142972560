import React from 'react';
import { LinkButton } from '@backstage/core-components';
import { FeatureCard } from './index';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';

export const ScaffolderFeatureCard = () => (
  <FeatureCard
    icon={<CreateComponentIcon />}
    title="Scaffolder"
    description="Generate new Github repositories from pre-prepared templates."
    readMoreLink="https://backstage.io/docs/features/software-templates/software-templates-index"
  >
    <LinkButton variant="contained" color="primary" to="/create">
      Go to Scaffolder
    </LinkButton>
  </FeatureCard>
);
