/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    height: 24,
    width: 'auto',
  },
  path: {
    fill: '#ffffff',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      viewBox="0 0 106 106"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.path}
        d="M103.832 46.649c-.881.322-1.434 2.438-1.303 4.161.422 5.668.898 12.088-3.213 21.884-1.954 4.664-5.484 9.53-10.192 14.062-5.927 5.706-10.903 6.988-12.178 6.988-.444 0-.712-.142-.8-.426-.032-.093-.098-.409.274-.676l1.051-.764c2.513-1.805 3.191-2.29 5.895-4.653 4.303-3.747 10.444-12.774 11.43-15.687 1.198-3.535 2.238-8.87 1.855-12.818-.236-2.498-.761-3.284-.87-3.35-.433.066-1.364 1.359-1.927 3.524-.498 1.91-1.172 4.178-1.829 6.344-1.833 6.07-3.678 8.302-5.462 10.456-.367.448-.734.89-1.1 1.364-3.175 4.118-7.253 6.338-8.222 6.338-.421 0-.558-.283-.59-.37-.148-.404.169-.78.684-1.222 1.302-1.108 5.703-5.264 7.673-12.29.334-1.194.006-2.432-.208-2.536 0 0-.421.098-1.072 1.707-1.38 3.404-1.604 3.737-3.783 6.927-2.682 3.933-7.559 7.899-9.874 9.012-3.618 1.74-7.553 2.618-11.686 2.618-2.463 0-5.052-.317-7.69-.933-5.873-1.38-12.82-7.2-13.257-7.67-1.91-2.066-3.848-4.286-5.085-6.49-3.246-5.793-4.636-11.395-4.127-16.653.52-5.433 1.582-9.66 5.26-13.97 3.634-4.26 6.864-7.117 12.6-9.136 2.857-1.009 6.442-1.565 10.088-1.565 5.096 0 9.66 1.085 12.534 2.967 4.998 3.284 6.782 4.986 11.216 9.426 2.008 2.018 5.112 3.223 8.292 3.223 2.37 0 4.653-.643 6.798-1.914 4.28-2.537 6.733-5.82 7.28-9.758 1.188-8.493-6.607-16.844-6.683-16.926a72.732 72.732 0 00-5.364-5.133c-.017-2.089-2.365-3.714-2.365-3.714C74.893-.321 62.726-.163 57.761.066 51.866.34 46.338.973 40.853 2.51 27.071 5.674 16.151 13.452 8.296 25.681 2.757 32.221.7 42.247.086 50.43-.587 59.276 2.85 72.53 4.733 74.303c1.763 1.653 3.312 1.888 3.514 1.91 1.073 2.285 2.217 4.663 2.693 5.345.356.513.712 1.036 1.073 1.56 2.217 3.24 4.51 6.59 7.575 9.251 5.058 4.39 9.596 6.813 15.72 9.502 6.076 2.673 10.756 3.796 16.678 4.014 2.091.082 3.536.115 4.834.115 4.729 0 8.133-.48 13.01-1.844 5.08-1.407 10.696-3.9 15.025-6.66 1.91-1.221 7.8-6.54 9.847-8.902 2.107-2.427 5.955-8.083 7.92-13.729 4.368-12.55 3.471-19.413 2.868-24.005-.372-2.908-1.1-4.211-1.658-4.211z"
      />
    </svg>
  );
};

export default LogoIcon;
